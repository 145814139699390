import React from "react";
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from "../components/layout";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./404.module.scss";


const NotFoundPage = () => {
	const data = useStaticQuery(graphql`
		query {
			options: wp {
				headerSection {
					ACFHeaderOptions {
						mainNavigationSettings {
							headerLogo {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 420){
											...GatsbyImageSharpFluid_withWebp
										}
									}
									publicURL
                					extension
								}
							}
						}
					}
				}
			}
			introBGMobile: file(name: {eq: "mask"}) {
				childImageSharp {
				fluid(maxWidth: 699) {
					...GatsbyImageSharpFluid_withWebp
				}
				}
			}
			introBGTablet: file(name: {eq: "mask"}) {
				childImageSharp {
				fluid(maxWidth: 550) {
					...GatsbyImageSharpFluid_withWebp
				}
				}
			}
			introBGDesktop: file(name: {eq: "mask"}) {
				childImageSharp {
				fluid(maxWidth: 840) {
					...GatsbyImageSharpFluid_withWebp
				}
				}
			}
			introBGXL: file(name: {eq: "mask"}) {
				childImageSharp {
				fluid(maxWidth: 1200) {
					...GatsbyImageSharpFluid_withWebp
				}
				}
			}
			arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
				childImageSharp {
				fixed(width: 10) {
					...GatsbyImageSharpFixed_withWebp
				}
				}
			}
			arrowBlue: file(name: {eq: "arrow_blue"}) {
				childImageSharp {
				  fixed(width: 9) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			locations: allWpLocation(sort: {fields: title}) {
				nodes {
				id
				link
				title
				birdeyeLocation {
					businessId
					timezone
					location {
						address1
						address2
						city
						state
						zip
						lat
						lng
					}
					name
					phone
					hoursOfOperations {
						day
						isOpen
						workingHours {
							startHour
							endHour
						}
					}
				}
				ACFLocation {
					bookMyAppointmentLink {
					target
					title
					url
					}
				}
				}
			}
		}
	`);

	const {
		options: {
			headerSection: {
				ACFHeaderOptions: {
					mainNavigationSettings: {
						headerLogo
					} 
				}
			}

		},
		locations: {
			nodes: locations
		},
		introBGMobile,
		introBGTablet,
		introBGDesktop,
		introBGXL,
		arrowBlue,
	} = data;

	return (
		<Layout>
			<Seo title="404: Not found" />
			<div className={styles.top}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }

				<div className={styles.left}>
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
					<h1 className={styles.servicename} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">Page not found</h1>
					<div className={styles.heading} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"><p>Oops! That page can’t be found.</p></div>
					<div className={styles.blurb} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in">
						<p>It looks like nothing was found at this location. Check for a url error then refresh this page, or return to the homepage.</p>
					</div>
					<Link to="/" className={styles.link} data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease-in">Return to homepage</Link>
				</div>

				<div className={styles.right}>
					<div className={styles.leftbottom}>
						<span className={styles.serviceheading}>View our locations:</span>
						<div className={styles.locationlist}>
							<div className={styles.inner}>
								<ul className={styles.list}>
									{ locations && locations.map((location, index) => {
										return (
											<li key={`lstloc-'${location.id}`} className={styles.listitem}>
												<Link to={location.link} className={styles.link}>
													<FixedImage passedClass={styles.icon} image={arrowBlue} />
													<span className={styles.title} dangerouslySetInnerHTML={{__html: location.title }}></span>
												</Link>	
											</li>
										)
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>


				
			</div>
		</Layout>
	)
}

export default NotFoundPage;
